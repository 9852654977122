.contact {
  background-color: #121212;
 
  }
  
.links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px;
  max-width: 300px;
  margin: 0 auto 0 auto;


}
.links a, .links img {
  width: 35px;
  height: 35px;
}

.hover {
  transform: scale(1.1);
    color:rgba(10,30,10);
  
}

